import { ActionTypes } from 'actions';
import { getUserEmail } from 'services/firebase';
import * as mapboxService from 'services/mapbox';
import * as searchService from 'services/search';
import * as firebaseService from 'services/firebase';
import * as elevationService from 'services/elevation';
import { sendAmplitudeEvent, sendAmplitudeData } from 'utils/amplitude';
import * as gageService from 'services/gage';
import { gageChartTimes, gageChartTypes } from 'lib/constants';
import moment from 'moment';
import { retrieveRegsInfoForStream, retrieveStreamMetadata } from 'services/regulations';

// let current_stream_gid2 = '';
/**
 * Updates the map coordinates stored in Redux.
 * @param {object} coords New coordinates for the map.
 */
export const updateMapCoordinates = ({ lat, lng, zoom }) => {
  return { type: ActionTypes.UPDATE_COORDINATES, payload: { lat, lng, zoom } };
};

/**
 * Updates the map's base layer.
 * @param {string} baseLayer Base layer to display.
 */
export const updateBaseLayer = (baseLayer) => {
  return { type: ActionTypes.UPDATE_BASE_LAYER, payload: baseLayer };
};

/**
 * Updates the map's style.
 * @param {string} style Base layer to display.
 */
export const updateMapStyle = (style) => {
  return { type: ActionTypes.UPDATE_MAP_STYLE, payload: style };
};

/**
 * Zooms the map in by incrementing the zoom by 1.
 */
export const zoomIn = () => {
  return { type: ActionTypes.ZOOM_IN };
};

/**
 * Zooms the map out by decrementing the zoom by 1.
 */
export const zoomOut = () => {
  return { type: ActionTypes.ZOOM_OUT };
};

/**
 * Updates the map filters.
 * @param {object} filterPatch Object containing updated keys/values for map filters.
 */
export const updateFilter = (filterPatch) => {
  return { type: ActionTypes.UPDATE_FILTER, payload: filterPatch };
};

/**
 * Updates the map classes.
 * @param {object} classPatch Object containing updated keys/values for map classes.
 */
export const updateClasses = (classPatch) => {
  return { type: ActionTypes.UPDATE_CLASSES, payload: classPatch };
};

/**
 * Updates the map's other features.
 * @param {object} featuresPatch Object containing updated keys/values for map features.
 */
export const updateOtherFeatures = (featuresPatch) => {
  return { type: ActionTypes.UPDATE_FEATURES, payload: featuresPatch };
};

/**
 * Formats the map pursuant to basic style.
 * @param {*} map Mapbox GL object.
 */
export const formatBasicStyle = (map) => {
  return (dispatch) => {
    mapboxService.formatBasicStyle(map);
    dispatch(updateOtherFeatures({
      accessPoints: false,
      gages: false,
      parking: false,
      hike: false,
      camp: false,
      boat: false,
    }));
  };
};

/**
 * Formats the map pursuant to guide style.
 * @param {*} map Mapbox GL object.
 */
export const formatGuideStyle = (map) => {
  return (dispatch) => {
    mapboxService.formatGuideStyle(map);
    dispatch(updateOtherFeatures({
      accessPoints: true,
      gages: true,
      parking: true,
      hike: true,
      camp: true,
      boat: true,
    }));
  };
};

/**
 * Formats the map pursuant to access style.
 * @param {*} map Mapbox GL object.
 */
export const formatAccessStyle = (map) => {
  return (dispatch) => {
    mapboxService.formatAccessStyle(map);
    dispatch(updateOtherFeatures({
      accessPoints: true,
      gages: true,
      parking: true,
      hike: true,
      camp: true,
      boat: true,
    }));
  };
};

export const formatRegsStyle = (map) => {
  return (dispatch) => {
    mapboxService.formatRegsStyle(map);
  };
};

/**
 * Updates the currently highlighted geographic stream, directly with the stream object.
 * @param {object} stream Favorite formatted stream object.
 */
export const updateStreamWithFavorite = (stream) => {
  return { type: ActionTypes.UPDATE_CURRENT_STREAM, payload: stream };
};

/**
 * Updates the currently highlighted geographic stream, using geocoded features.
 * @param {object} stream Stream feature object.
 */
export const updateStreamWithGeocoding = (stream) => {
  return (dispatch, getState) => {
    const {
      GeoBoundMaxX2, GeoBoundMaxY2, GeoBoundMinX2, GeoBoundMinY2, PrimaryLabel, SecondaryLabel, stream_gid2, part_count, GeoBoundMaxX, GeoBoundMaxY, GeoBoundMinX, GeoBoundMinY, SectionName, StreamClass, is_popular, popular, is_tailwater,
      has_easement, HasEasement, len,
    } = stream;
    const email = getUserEmail();

    if (getState().map.stream.stream_gid !== stream_gid2) {
      dispatch({
        type: ActionTypes.UPDATE_CURRENT_STREAM,
        payload: {
          primaryLabel: PrimaryLabel,
          secondaryLabel: SecondaryLabel,
          stream_gid: stream_gid2,
          xmax: GeoBoundMaxX2,
          ymax: GeoBoundMaxY2,
          xmin: GeoBoundMinX2,
          ymin: GeoBoundMinY2,
          userName: email,
          userName2: email,
          is_popular: is_popular || popular,
          is_tailwater,
          has_easement: has_easement || HasEasement,
          length: len,
          ...(part_count) && { part_count },
          ...(GeoBoundMaxX) && { GeoBoundMaxX },
          ...(GeoBoundMaxY) && { GeoBoundMaxY },
          ...(GeoBoundMinX) && { GeoBoundMinX },
          ...(GeoBoundMinY) && { GeoBoundMinY },
          ...(SectionName) && { SectionName },
          ...(!Number.isNaN(StreamClass)) && { StreamClass },
        },
      });
      dispatch(updateStreamGeography({ length: len }));
    }
  };
};

/**
 * Clears the current search.
 */
export const clearSearch = () => {
  return { type: ActionTypes.UPDATE_SEARCH_RESULTS, payload: [] };
};

/**
 * Searches by pre-defined search functions, updates the map.
 * @param {string} query Query to search by.
 */
export const search = (query) => {
  return async (dispatch) => {
    try {
      dispatch(clearSearch());
      const [features, mapboxFeatures] = await Promise.all([searchService.searchFeatures(query), searchService.getMapboxFeatures(query)]);
      dispatch({ type: ActionTypes.UPDATE_SEARCH_RESULTS, payload: [...features, ...mapboxFeatures] });
    } catch (e) {
      console.log(e);
    }
  };
};

/**
 * Updates the status of the active tools.
 * @param {object} tools Keys should correspond with the tools in Redux.
 */
export const setActiveTools = (tools) => {
  return { type: ActionTypes.UPDATE_ACTIVE_TOOLS, payload: tools };
};

/**
 * Sets the current temporary marker.
 * @param {Marker} marker A marker object.
 */
export const setTempMarker = (marker) => {
  return { type: ActionTypes.SET_TEMP_MARKER, payload: marker };
};

/**
 * Sets the current temporary marker.
 * @param {Marker} marker A marker object.
 */
export const setTapMarker = (marker) => {
  return { type: ActionTypes.SET_TAP_MARKER, payload: marker };
};

/**
 * Sets the Red temporary marker.
 * @param {Marker} marker A marker object.
 */
export const setRedMarker = (marker) => {
  return { type: ActionTypes.SET_RED_MARKER, payload: marker };
};

export const setLocationMarker = (marker) => {
  return { type: ActionTypes.SET_LOCATION_MARKER, payload: marker };
};

/**
 * Sets the current map object.
 * @param {*} map Current MapGL reference.
 */
export const setMap = (map) => {
  return { type: ActionTypes.SET_MAP, payload: map };
};

/**
 * Sets the current draw object.
 * @param {*} draw Current Draw reference.
 */
export const setDraw = (draw) => {
  return { type: ActionTypes.SET_DRAW, payload: draw };
};

/**
 * Set with a delay when a marker, line, or area is selected to allow for proper "click to clear" functionality.
 * @param {bool} isShown Whether the current panel is shown.
 */
export const setDelayedPanelShown = (isShown) => {
  return { type: ActionTypes.SET_DELAYED_PANEL, payload: isShown };
};

/**
 * Helper function to reset the current temporary marker.
 */
export const resetCurrentMarker = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_TEMP_MARKER, payload: null });
    dispatch({ type: ActionTypes.SET_EDITING_MARKER_ID, payload: '' });
    dispatch({ type: ActionTypes.SET_MARKER_TYPE, payload: 0 });
    dispatch(setDelayedPanelShown(false));
  };
};

/**
 * Helper function to reset the current temporary line.
 */
export const resetCurrentLine = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_TEMP_LINE, payload: null });
    dispatch({ type: ActionTypes.SET_EDITING_LINE_ID, payload: '' });
    dispatch(setDelayedPanelShown(false));
  };
};

/**
 * Helper function to reset the current temporary area.
 */
export const resetCurrentArea = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.SET_TEMP_AREA, payload: null });
    dispatch({ type: ActionTypes.SET_EDITING_AREA_ID, payload: '' });
    dispatch(setDelayedPanelShown(false));
  };
};

/**
 * Adds a marker to the map.
 * @param {object} Options Options to upload to Firebase.
 * @param {string} updateId Key of an existing marker's entry in Firebase to replace.
 * @param {function} callback The callback function.
 */
export const createMarker = ({
  markerType, lngLat, name, notes, pointColorIndex,
}, updateId, callback) => {
  return async (dispatch) => {
    const markerObject = {};
    const email = firebaseService.getUserEmail();
    markerObject.geometry = {
      type: 'Point',
      coordinates: [lngLat.lng, lngLat.lat],
    };
    markerObject.properties = {
      publishStatus: '0',
      activeStatus: '1',
      userName: email,
      userName2: email,
      note: notes,
      title: name,
      pointType: markerType.index.toString(),
      date: new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(new Date()),
      pointColorIndex,
    };
    markerObject.type = 'Feature';

    let id = updateId;

    if (!updateId) {
      id = firebaseService.addMarker(markerObject);
    } else {
      delete markerObject.properties.date;
      await firebaseService.updateMarker(markerObject, updateId);
    }

    if (callback) callback(id);
  };
};

/**
 * Uploads the line to Firebase.
 * @param {Feature} line A Mapbox GL feature object.
 * @param {string} updateId Optional. If provided, the area will replace the data at the provided ID.
 * @param {function} callback The callback function.
 */
export const createLine = ({
  colorIndex, title, note, geometry, widthIndex, typeIndex,
}, updateId, callback) => {
  return (dispatch) => {
    const email = firebaseService.getUserEmail();

    const uploadFeature = {};
    uploadFeature.geometry = geometry;
    uploadFeature.properties = {
      date: moment().format('MM/DD/yyyy HH:mm:ssZZ'),
      activeStatus: '1',
      publishStatus: '0',
      title,
      note,
      lineColorIndex: colorIndex,
      lineWidthIndex: widthIndex,
      lineTypeIndex: typeIndex,
      userName: email,
    };
    uploadFeature.type = 'Feature';

    let id = updateId;

    if (!updateId) {
      id = firebaseService.addLine(uploadFeature);
    } else {
      delete uploadFeature.properties.date;
      firebaseService.updateLine(uploadFeature, updateId);
    }

    if (callback) callback(id);
  };
};

/**
 * Uploads the area to Firebase.
 * @param {Feature} area A Mapbox GL feature object.
 * @param {string} updateId Optional. If provided, the area will replace the data at the provided ID.
 * @param {function} callback The callback function.
 */
export const createArea = ({
  colorIndex, title, note, geometry, widthIndex, typeIndex, outlineOpacity, fillOpacity,
}, updateId, callback) => {
  return (dispatch) => {
    const email = firebaseService.getUserEmail();

    const uploadFeature = {};
    uploadFeature.geometry = geometry;
    uploadFeature.properties = {
      date: moment().format('MM/DD/yyyy HH:mm:ssZZ'),
      activeStatus: '1',
      publishStatus: '0',
      title,
      note,
      fillColorIndex: colorIndex,
      lineWidthIndex: widthIndex,
      lineTypeIndex: typeIndex,
      outlineOpacity,
      fillOpacity,
      userName: email,
    };
    uploadFeature.type = 'Feature';

    let id = updateId;

    if (!updateId) {
      id = firebaseService.addArea(uploadFeature);
    } else {
      delete uploadFeature.properties.date;
      firebaseService.updateArea(uploadFeature, updateId);
    }

    if (callback) callback(id);
  };
};

/**
 * Deletes a marker with a specific ID.
 * @param {string} markerId Marker ID.
 */
export const deleteMarker = (markerId) => {
  return (dispatch) => {
    firebaseService.deleteMarker(markerId);
    dispatch(resetCurrentMarker());
  };
};

/**
 * Deletes a line with a specific ID.
 * @param {string} lineId Line ID.
 */
export const deleteLine = (lineId) => {
  return (dispatch) => {
    firebaseService.deleteLine(lineId);
    dispatch(resetCurrentLine());
  };
};

/**
 * Deletes an area with a specific ID.
 * @param {string} areaId Area ID.
 */
export const deleteArea = (areaId) => {
  return (dispatch) => {
    firebaseService.deleteArea(areaId);
    dispatch(resetCurrentArea());
  };
};

/**
 * Sets the ID of the marker currently being edited.
 * @param {string} markerId Firebase Marker ID.
 */
export const setEditingMarkerId = (markerId) => {
  return { type: ActionTypes.SET_EDITING_MARKER_ID, payload: markerId };
};

/**
 * Sets the current marker type to display.
 * @param {number} typeIndex Index of the current marker type.
 */
export const setMarkerType = (typeIndex) => {
  return { type: ActionTypes.SET_MARKER_TYPE, payload: typeIndex };
};

/**
 * Sets the temporary line for creating/editing.
 * @param {Feature} line A Mapbox GL feature object.
 */
export const setTempLine = (line) => {
  return { type: ActionTypes.SET_TEMP_LINE, payload: line };
};

/**
 * Sets the ID of the line currently being edited.
 * @param {string} lineId Mapbox GL line ID.
 */
export const setEditingLineId = (lineId) => {
  return { type: ActionTypes.SET_EDITING_LINE_ID, payload: lineId };
};

/**
 * Sets the ID of the area currently being edited.
 * @param {string} areaId Mapbox GL area ID.
 */
export const setEditingAreaId = (areaId) => {
  return { type: ActionTypes.SET_EDITING_AREA_ID, payload: areaId };
};

/**
 * Sets the ID of the area currently being edited.
 * @param {Feature} area Mapbox GL polygon.
 */
export const setTempArea = (area) => {
  return { type: ActionTypes.SET_TEMP_AREA, payload: area };
};

/**
 * Retrieve the geographical data about a stream
 * @param {Array} streamElevationData Array of elevation data of a stream.
 * @returns {Object} Object containing peakElevation, elevChange, slope values
 */
export const getElevationMetadata = (streamElevationData) => {
  const elevArray = streamElevationData.map((obj) => obj.elevation);

  const elevMin = Math.min(...elevArray);
  const elevMax = Math.max(...elevArray);
  const elevChange = elevMax - elevMin;

  const elevChangeFeet = parseInt((elevChange * 3.28084).toFixed(0), 10);

  const peakElev = parseInt((elevMax * 3.28084).toFixed(0), 10);

  const coordinatesArray = streamElevationData.map((obj) => obj.location);
  let riverDistance = 0.0;

  for (let i = 1; i < coordinatesArray.length; i += 1) {
    const d = mapboxService.distance([coordinatesArray[i - 1].lng(), coordinatesArray[i - 1].lat()], [coordinatesArray[i].lng(), coordinatesArray[i].lat()]);
    riverDistance += d;
  }

  // calculated length of a stream
  const riverDistanceMiles = parseFloat((riverDistance * 0.000621371).toFixed(1));

  const slope = parseFloat(((elevChange / riverDistance) * 100).toFixed(1));

  return {
    peakElevation: peakElev, elevChange: elevChangeFeet, slope, riverDistanceMiles,
  };
};

/**
 * Sets the geography data manually.
 * @param {Object} data Object containing geography data.
 */
export const setStreamGeography = (data) => {
  return { type: ActionTypes.SET_STREAM_GEOGRAPHY, payload: data };
};

/**
 * Updates the geography data manually.
 * @param {Object} data Object containing keys that needs to be updated of geography data.
 */
export const updateStreamGeography = (data) => {
  return { type: ActionTypes.UPDATE_STREAM_GEOGRAPHY, payload: data };
};

/**
 * Sets the elevation data manually.
 * @param {array[elevationResult]} data Array of Google results.
 */
export const setStreamElevationData = (data) => {
  return { type: ActionTypes.SET_STREAM_ELEVATION, payload: data };
};

/**
 * Sets the elevation data for a given stream.
 * @param {string} streamGid ID of the stream to fetch data for.
 */
export const setStreamElevationDataWithId = (streamGid) => {
  return async (dispatch) => {
    try {
      const results = await elevationService.getElevationData(streamGid);
      const geo = getElevationMetadata(results);
      dispatch(setStreamElevationData(results));
      dispatch(updateStreamGeography(geo));
    } catch (e) {
      console.log(e);
    }
  };
};

/**
 * If the data exists in Firebase, updates with data for the current stream.
 * @param {string} streamGid ID of the stream to fetch data for.
 */
export const getStreamUserData = (streamGid) => {
  return async (dispatch) => {
    try {
      if (!streamGid) dispatch({ type: ActionTypes.SET_STREAM_USER_DATA, payload: {} });

      const results = await firebaseService.getStreamData(streamGid);
      dispatch({ type: ActionTypes.SET_STREAM_USER_DATA, payload: results || {} });
    } catch (e) {
      console.log(e);
    }
  };
};

/**
 * Toggle the visibility of the elevation chart.
 * @param {boolean} visible Visibility setting.
 */
export const setElevationChartVisible = (visible) => {
  return { type: ActionTypes.SET_ELEVATION_CHART_VISIBLITY, payload: visible };
};

export const setRiverMilesVisible = (visible) => {
  return { type: ActionTypes.SET_RIVER_MILES_VISIBLITY, payload: visible };
};

export const setRiverMilesCardVisible = (visible, map) => {
  mapboxService.toggleAccessPoints(visible, map);
  if (!visible) {
    mapboxService.setRiverMilesFilters(map);
  }
  return setRiverMilesVisible(visible);
};

export const setRiverMilesPrimnaryLabel = (label) => {
  return { type: ActionTypes.SET_RIVER_MILES_PRIMARY_LABEL, payload: label };
};

export const setRiverMilesStep = (stepNumber) => {
  return { type: ActionTypes.SET_RIVER_MILES_STEP, payload: stepNumber };
};

export const setRiverMilesMarkers = (marker) => {
  return { type: ActionTypes.SET_RIVER_MILES_MARKERS, payload: marker };
};

export const setRiverMilesParentLine = (data) => {
  return { type: ActionTypes.SET_RIVER_MILES_PARENT_LINE, payload: data };
};

export const setRiverMilesSlicedLineCoordinates = (data) => {
  return { type: ActionTypes.SET_RIVER_MILES_SLICED_LINE_COORDINATES, payload: data };
};

export const setRiverMilesDistance = (data) => {
  return { type: ActionTypes.SET_RIVER_MILES_DISTANCE, payload: data };
};

/**
 * Sets the slope chart visisbility manually.
 * @param {Boolean} visible value of visibility
 */
export const setSlopeChartVisible = (visible) => {
  return { type: ActionTypes.SET_SLOPE_CHART_VISIBLITY, payload: visible };
};

/**
 * Adds an elevation marker to the map.
 * @param {object} lngLat MapboxGL lngLat object.
 */
export const setTempElevationMarker = (lngLat) => {
  return (dispatch, getState) => {
    const { map, elevationMarker } = getState().map;
    if (elevationMarker) elevationMarker.remove();

    const marker = mapboxService.createMarkerWithoutIcon({ lngLat }, map);
    dispatch({ type: ActionTypes.SET_ELEVATION_MARKER, payload: marker });
  };
};

/**
 * Removes the temporary elevation marker from the map.
 */
export const removeTempElevationMarker = () => {
  return (dispatch, getState) => {
    const { elevationMarker } = getState().map;
    if (elevationMarker) elevationMarker.remove();

    dispatch({ type: ActionTypes.SET_ELEVATION_MARKER, payload: null });
  };
};

/**
 * Sets the current gage for the map.
 * @param {object} gage Gage object.
 */
export const setCurrentGage = (gage) => {
  return { type: ActionTypes.SET_CURRENT_GAGE, payload: gage };
};

/**
 * Sets the data for the current gage selected in the app.
 */
export const setCurrentGageUSGSData = () => {
  return async (dispatch, getState) => {
    const { gage } = getState().map;
    if (gage?.properties?.STAID) {
      gageService.makeGageRequest(gageChartTypes.flow, gage.properties.STAID, gageChartTimes.month).then((flowData) => {
        dispatch({ type: ActionTypes.SET_GAGE_DATA, payload: { flow: flowData } });
        dispatch({ type: ActionTypes.SET_GAGE_DATA, payload: { flowId: gage.properties.STAID } });
      })
        .catch((e) => console.log(e));

      gageService.makeGageRequest(gageChartTypes.height, gage.properties.STAID, gageChartTimes.month).then((heightData) => {
        dispatch({ type: ActionTypes.SET_GAGE_DATA, payload: { height: heightData } });
        dispatch({ type: ActionTypes.SET_GAGE_DATA, payload: { heightId: gage.properties.STAID } });
      })
        .catch((e) => console.log(e));
    }
  };
};

export const setIsGageChartShown = (show) => {
  return { type: ActionTypes.SET_IS_GAGE_CHART_SHOWN, payload: show };
};

export const clearGageData = () => {
  return { type: ActionTypes.CLEAR_GAGE_DATA };
};

export const setChartType = (type) => {
  return { type: ActionTypes.SET_CURRENT_CHART_TYPE, payload: type };
};

export const setChartTime = (time) => {
  return { type: ActionTypes.SET_CURRENT_TIME, payload: time };
};

export const setActiveSidebar = (pane) => {
  return { type: ActionTypes.SET_ACTIVE_SIDEBAR, payload: pane };
};

export const clearMap = () => {
  return (dispatch, getState) => {
    dispatch(updateStreamWithGeocoding({}));
    dispatch(setStreamElevationData([]));
    dispatch(setStreamRegulations([]));

    dispatch(setIsGageChartShown(false));

    if (getState().map.delayedPanelShown) {
      if (getState().map.area.tempArea) {
        const { map, area } = getState().map;
        mapboxService.clearAreaBorder(map, area.tempArea);
      }

      dispatch(resetCurrentArea());
      dispatch(resetCurrentLine());
      dispatch(resetCurrentMarker());
    }

    dispatch(setActiveSidebar(''));
    dispatch(setDelayedPanelShown(false));
    dispatch(setisFileUploadSectionShown(false));
  };
};

export const setIsUpgradeModalShown = (isShown) => {
  return { type: ActionTypes.SET_UPGRADE_MODAL, payload: isShown };
};

export const setIsSnapModalShown = (isShown) => {
  return { type: ActionTypes.SET_SNAP_MODAL, payload: isShown };
};

export const setisFileUploadSectionShown = (isShown) => {
  return { type: ActionTypes.SET_GOOGLE_PLACES_UPLOAD, payload: isShown };
};

export const setIsConfirmUploadModalShown = (isShown) => {
  return { type: ActionTypes.SET_CONFIRM_UPLOAD_MODAL, payload: isShown };
};

export const setStreamRegulations = (regs) => {
  return { type: ActionTypes.SET_STREAM_REGULATIONS, payload: regs };
};

export const setStreamMetadata = (seasons) => {
  return { type: ActionTypes.SET_STREAM_METADATA, payload: seasons };
};

export const setStreamFlyShops = (shops) => {
  return { type: ActionTypes.SET_STREAM_FLY_SHOPS, payload: shops };
};

export const setStreamRegulationsForCurrentStream = (map) => {
  return (dispatch, getState) => {
    const { stream } = getState().map;
    const {
      xmin, xmax, ymin, ymax,
    } = stream;

    try {
      const min = map.project([xmin, ymin]);
      const max = map.project([xmax, ymax]);
      const bbox = [
        [min.x, min.y],
        [max.x, max.y],
      ];

      const regs = retrieveRegsInfoForStream(map, bbox);
      const { seasons, flyShops } = retrieveStreamMetadata(map, bbox);
      dispatch(setStreamRegulations(regs.filter((reg) => !!reg)));
      dispatch(setStreamMetadata(seasons));
      dispatch(setStreamFlyShops(flyShops));
    } catch (e) {
      console.log(e);
    }
  };
};

export const setStreamFlowsForCurrentStream = (map, currentHUC) => {
  return async (dispatch, getState) => {
    try {
      const { stream } = getState().map;
      const {
        xmin, xmax, ymin, ymax,
      } = stream;
      const min = map.project([xmin + 0.005, ymin + 0.005]);
      const max = map.project([xmax - 0.005, ymax - 0.005]);
      const bbox = [
        [min.x, min.y],
        [min.x, min.y],
      ];

      const hucs = map.queryRenderedFeatures(bbox, { layers: ['huc_10'] });
      let curHuc = currentHUC;
      if (hucs.length > 0) {
        curHuc = hucs[0]?.properties?.huc10;
        dispatch(setCurrentHUC(curHuc));
      }

      const gages = await gageService.retrieveGages(stream, curHuc);
      dispatch(setGageData(gages));
      console.log(gages);
    } catch (e) {
      console.log(e);
    }
  };
};

export const setSupportedStates = (states) => {
  return { type: ActionTypes.SET_SUPPORTED_STATES, payload: states };
};

export const setElevation = (elevation) => {
  return { type: ActionTypes.UPDATE_ELEVATION, payload: elevation };
};

export const setGageData = (gages) => {
  return { type: ActionTypes.SET_STREAM_GAGE_DATA, payload: gages };
};

export const setCurrentHUC = (huc) => {
  return { type: ActionTypes.SET_CURRENT_HUC, payload: huc };
};

export const setTRPOIFeature = (trPOIFeature) => {
  return { type: ActionTypes.SET_TR_POI_FEATURE, payload: trPOIFeature };
};
