import axios from 'axios';
import { capitalizeSentence } from 'utils/string';
import { getGageIDList } from './firebase';

const API_URL_GAGE = 'https://troutroutes.netlify.app/.netlify/functions/gage';

const getUSGSURL = (stationId) => {
  return `https://waterservices.usgs.gov/nwis/iv/?format=json&site=${stationId}&parameterCd=00060,00065,00010&siteStatus=all`;
};

export const makeGageRequest = async (type, stationId, time) => {
  const { data } = await axios.get(`${API_URL_GAGE}?type=${type}&id=${stationId}&time=${time}`);
  return data[0];
};

const FlowIdentifierString = '00060';
const HeightIdentifierString = '00065';
const TemperatureIdentifierString = '00010';
const iff = (condition, then, otherwise) => { return condition ? then : otherwise; };

export const typeStringFromDataType = (type) => {
  if (!type) {
    return `${FlowIdentifierString},${HeightIdentifierString},${TemperatureIdentifierString}`;
  }

  switch (type) {
  case 'flow':
    return FlowIdentifierString;
  case 'height':
    return HeightIdentifierString;
  case 'temp':
    return TemperatureIdentifierString;
  default:
    return ''; // Add a default return to handle any unexpected cases
  }
};
export const getGageData = async (stationID, stationTitle = 'Unknown Gage', timeRange = 'month', type = null, completion) => {
  const localGageObject = {
    title: stationTitle,
    id: stationID,
    provider: 'USGS',
    streamFlowAvailable: 0,
    streamHeightAvailable: 0,
    streamTempAvailable: 0,
    IVFlowArray: [],
    IVHeightArray: [],
    IVTempArray: [],
    medianFlowArray: [],
    medianHeightArray: [],
    medianTempArray: [],
  };

  // Format URL
  const date = new Date();
  const formatDate = (d) => d.toISOString().split('T')[0];
  const today = formatDate(date);

  const pastDate = new Date(date);
  if (timeRange === 'month') {
    pastDate.setMonth(date.getMonth() - 1);
  } else if (timeRange === 'week') {
    pastDate.setDate(date.getDate() - 7);
  } else if (timeRange === 'year') {
    pastDate.setFullYear(date.getFullYear() - 1);
  } else if (timeRange === 'day') {
    pastDate.setDate(date.getDate() - 1);
  }
  const pastDateFormatted = formatDate(pastDate);

  const baseUrl = 'https://nwis.waterservices.usgs.gov/nwis/iv/';
  const parameters = {
    format: 'json',
    site: stationID,
    startDT: pastDateFormatted,
    endDT: today,
    parameterCd: typeStringFromDataType(type), // Assume this function is defined elsewhere
    siteStatus: 'all',
  };

  const queryParams = new URLSearchParams(parameters).toString();
  const url = `${baseUrl}?${queryParams}`;

  try {
    const response = await fetch(url, { method: 'GET' });
    if (!response.ok) throw new Error('Network response was not ok');

    const data = await response.json();
    if (data && data.value) {
      const timeseries = data.value.timeSeries || {};

      if (timeseries.length > 0) {
        const { sourceInfo } = timeseries[0];
        if (sourceInfo) {
          localGageObject.title = (sourceInfo.siteName || stationTitle).toUpperCase();
          const siteCodeObj = sourceInfo.siteCode;
          if (siteCodeObj && siteCodeObj.length > 0) {
            localGageObject.id = siteCodeObj[0].value;
          }
        }

        const flowData = [];
        const heightData = [];
        const tempData = [];

        for (const serie of timeseries) {
          let type = '0';
          const variableType = serie.variable;
          if (variableType && variableType.variableCode) {
            type = variableType.variableCode[0].value;
          }

          const valueDictsArray = serie.values;
          if (valueDictsArray && valueDictsArray[0] && valueDictsArray[0].value) {
            const valueDicts = valueDictsArray[0].value;

            for (const valueDict of valueDicts) {
              const valueString = valueDict.value;
              const { dateTime } = valueDict;
              const valueDouble = parseFloat(valueString);

              if (valueString && !Number.isNaN(valueDouble) && dateTime) {
                if (type === FlowIdentifierString) {
                  if (valueDouble >= 0) {
                    flowData.push({ value: valueString, dateTime });
                  }
                } else if (type === HeightIdentifierString) {
                  if (valueDouble >= 0) {
                    heightData.push({ value: valueString, dateTime });
                  }
                } else if (type === TemperatureIdentifierString) {
                  const tempF = (valueDouble * (9 / 5)) + 32;
                  const roundedTempF = Math.round(tempF * 100) / 100;
                  if (roundedTempF >= 0) {
                    tempData.push({ value: roundedTempF.toString(), dateTime });
                  }
                }
              }
            }
          }
        }

        localGageObject.streamFlowAvailable = flowData.length > 0 ? iff(parseFloat(flowData.at(-1).value) < 0, 2, 1) : 0;
        localGageObject.streamHeightAvailable = heightData.length > 0 ? iff(parseFloat(heightData.at(-1).value) < 0, 2, 1) : 0;
        localGageObject.streamTempAvailable = tempData.length > 0 ? 1 : 0;

        localGageObject.IVFlowArray = flowData;
        localGageObject.IVHeightArray = heightData;
        localGageObject.IVTempArray = tempData;
      }
    }
    completion(localGageObject);
  } catch (error) {
    completion(localGageObject);
  }
};
export const getGageMedianData = async (gage, reportType = 'daily', completion) => {
  const baseUrl = 'https://waterservices.usgs.gov/nwis/stat/';
  const parameters = {
    format: 'rdb',
    site: gage.id || '',
    statReportType: reportType,
    statTypeCd: 'median',
    parameterCd: '00060,00065,00010',
  };

  const queryParams = new URLSearchParams(parameters).toString();
  const url = `${baseUrl}?${queryParams}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
    });

    if (!response.ok) {
      completion(gage);
      return;
    }

    const resultString = await response.text();

    if (!resultString) {
      completion(gage);
      return;
    }

    // Assuming `parseMedianRDB` is a function defined in your codebase
    const medianValues = parseMedianRDB(resultString);

    const updatedGage = {
      ...gage,
      medianFlowArray: medianValues.flows,
      medianHeightArray: medianValues.heights,
      medianTempArray: medianValues.temps,
    };

    completion(updatedGage);
  } catch (error) {
    completion(gage);
  }
};
const parseMedianRDB = (flatString) => {
  const medianData = {
    flows: [],
    heights: [],
    temps: [],
  };

  let rows = flatString.split('\n');
  rows = trimHeader(rows); // Assuming trimHeader is a utility function you have

  const header = rows.shift().split('\t');
  const parameterCodeIndex = header.indexOf('parameter_cd');
  const medianValueIndex = header.indexOf('p50_va');
  const monthIndex = header.indexOf('month_nu');
  const dayIndex = header.indexOf('day_nu');
  const seriesIdIndex = header.indexOf('loc_web_ds');

  const currentYear = new Date().getFullYear();

  rows.forEach((row) => {
    const columns = row.split('\t');

    if (
      parameterCodeIndex === -1
      || medianValueIndex === -1
      || monthIndex === -1
      || dayIndex === -1
      || columns.length <= Math.max(parameterCodeIndex, medianValueIndex, monthIndex, dayIndex)
    ) {
      return;
    }

    if (seriesIdIndex !== -1 && columns[seriesIdIndex] && columns[seriesIdIndex].trim() !== '') {
      return;
    }

    const valueObjectInstance = {
      value: columns[medianValueIndex],
      dateTime: `${currentYear}-${columns[monthIndex]}-${columns[dayIndex]}`,
    };

    switch (columns[parameterCodeIndex]) {
    case '00060':
      medianData.flows.push(valueObjectInstance);
      break;
    case '00065':
      medianData.heights.push(valueObjectInstance);
      break;
    case '00010': {
      const tempC = parseFloat(valueObjectInstance.value) || 0.0;
      const tempF = (tempC * (9 / 5)) + 32;
      const roundedTempF = Math.round(tempF * 100) / 100.0;
      const tempFStr = roundedTempF.toString();
      medianData.temps.push({ value: tempFStr, dateTime: valueObjectInstance.dateTime });
    }
      break;
    default:
      console.log('Unknown parameter code');
    }
  });

  return medianData;
};
const trimHeader = (rows) => {
  const rowsCopy = [...rows];
  while (rowsCopy.length > 0) {
    if (rowsCopy[0].startsWith('#')) {
      rowsCopy.shift(); // Remove the first element if it starts with '#'
    } else {
      break; // Stop if the row does not start with '#'
    }
  }
  return rowsCopy;
};

export const fetchObservedDataForColoradoGage = async (gage, timeRange = 'month') => {
  const flowKey = 'DISCHRG';
  const secondaryFlowKey = 'DISCHRG1';
  const heightKey = 'GAGE_HT';

  const host = 'https://dwr.state.co.us';
  const path = '/Rest/GET/api/v2/telemetrystations/telemetrytimeseriesraw/';

  // Format the current and past date
  const currentDate = new Date();
  const pastDate = new Date();

  // Adjust the past date based on timeRange
  if (timeRange === 'month') {
    pastDate.setMonth(currentDate.getMonth() - 1);
  }
  // Add more timeRange options if needed

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const pastDateFormatted = formatDate(pastDate);

  const queryParams = new URLSearchParams({
    abbrev: gage.id,
    startDate: pastDateFormatted,
    endDate: 'now',
  });

  try {
    const response = await fetch(`${host}${path}?${queryParams}`);
    const data = await response.json();

    const decodedData = data.ResultList;
    const flow = [];
    const height = [];

    decodedData.forEach((item) => {
      const valueObject = {
        value: String(item.measValue),
        dateTime: item.measDateTime,
      };

      if (item.parameter === flowKey || item.parameter === secondaryFlowKey) {
        flow.push(valueObject);
      } else if (item.parameter === heightKey) {
        height.push(valueObject);
      }
    });

    const updatedGage = { ...gage };
    if (flow.length > 0) {
      updatedGage.IVFlowArray = flow;
      updatedGage.streamFlowAvailable = 1;
    }
    if (height.length > 0) {
      updatedGage.IVHeightArray = height;
      updatedGage.streamHeightAvailable = 1;
    }

    return updatedGage;
  } catch (error) {
    console.error(error);
    return gage;
  }
};

export const fetchObservedDataForNOAAGage = async (gage) => {
  const host = 'https://api.water.noaa.gov';
  const path = `/nwps/v1/gauges/${gage.id}/stageflow`;
  const flowKey = 'flow';
  const heightKey = 'stage';
  const tideHeightKey = 'tide height';
  const damHeightKey = 'pool';

  try {
    const response = await fetch(`${host}${path}?abbrev=${gage.id}`);
    const decodedData = await response.json();

    const flow = [];
    const height = [];

    decodedData.observed.data.forEach((item) => {
      const primaryName = decodedData.observed.primaryName.toLowerCase();
      const secondaryName = decodedData.observed.secondaryName.toLowerCase();

      const primaryValue = item.primary >= 0 ? item.primary : null;
      const secondaryValue = item.secondary >= 0 ? item.secondary : null;

      if (primaryName === flowKey && primaryValue !== null) {
        const flowValue = decodedData.observed.primaryUnits === 'kcfs' ? primaryValue * 1000 : primaryValue;
        flow.push({ value: String(flowValue), dateTime: item.validTime });
      } else if ([heightKey, tideHeightKey, damHeightKey].includes(primaryName) && primaryValue !== null) {
        height.push({ value: String(primaryValue), dateTime: item.validTime });
      }

      if (secondaryName === flowKey && secondaryValue !== null) {
        const flowValue = decodedData.observed.secondaryUnits === 'kcfs' ? secondaryValue * 1000 : secondaryValue;
        flow.push({ value: String(flowValue), dateTime: item.validTime });
      } else if ([heightKey, tideHeightKey, damHeightKey].includes(secondaryName) && secondaryValue !== null) {
        height.push({ value: String(secondaryValue), dateTime: item.validTime });
      }
    });

    const updatedGage = { ...gage };
    if (flow.length > 0) {
      updatedGage.IVFlowArray = flow;
      updatedGage.streamFlowAvailable = 1;
    }
    if (height.length > 0) {
      updatedGage.IVHeightArray = height;
      updatedGage.streamHeightAvailable = 1;
    }

    return updatedGage;
  } catch (error) {
    console.error(error);
    return gage;
  }
};

export const fetchFlowForecastForReach = async (reachId) => {
  const dataKey = 'longRange';
  const host = 'https://api.water.noaa.gov';
  const path = `/nwps/v1/reaches/${reachId}/streamflow`;

  try {
    const response = await fetch(`${host}${path}?series=long_range`);
    const responseData = await response.json();

    if (!responseData || !responseData[dataKey]) {
      return [];
    }

    const dataContainer = responseData[dataKey];
    const { mean } = dataContainer || {};
    if (!mean || !mean.data) {
      return [];
    }

    const { data } = mean || {};
    const results = data.reduce((acc, dataDict) => {
      const dateString = dataDict.validTime;
      if (dateString && dataDict.flow !== undefined) {
        const flow = parseFloat(dataDict.flow);
        acc.push({ validTimeString: dateString, flow, height: null });
      }
      return acc;
    }, []);

    return results;
  } catch (error) {
    return [];
  }
};

export const getLatestGageData = async (stationId) => {
  const url = getUSGSURL(stationId);
  const result = await axios.get(url);

  const timeSeries = result?.data?.value?.timeSeries;
  let gageObject;

  if(timeSeries.length > 0) {
    gageObject = {
      title: capitalizeSentence(timeSeries[0]?.sourceInfo?.siteName),
      id: timeSeries[0]?.sourceInfo?.siteCode[0]?.value,
      coordinate: {
        latitude: timeSeries[0]?.sourceInfo?.geoLocation?.geogLocation?.latitude,
        longitude: timeSeries[0]?.sourceInfo?.geoLocation?.geogLocation?.longitude,
      },
    };
  }

  for(const series of timeSeries) {
    const valuesArray = [];
    const doubleArray = [];
    const type = series?.variable?.variableCode[0]?.value;

    for(const value of series?.values[0]?.value) {
      const tempObject = {
        value: value?.value || '-99',
        dateTime: value?.dateTime || '2000-01-01T00:00:00',
      };
      doubleArray.push(parseFloat(tempObject.value));
      valuesArray.push(tempObject);
    }

    switch(type) {
    case '00060':
      if(doubleArray.length < 1) gageObject.streamFlowAvailable = 0;
      else if (doubleArray[doubleArray.length - 1] < 0.0) {
        gageObject.streamFlowAvailable = 2;
      } else {
        const validArray = [];
        for(const value of valuesArray) {
          let validValue = '0.0';
          if(parseFloat(value?.value) >= 0.0) {
            validValue = value?.value;
          }
          validArray.push({ ...value, value: validValue });
        }
        gageObject.streamFlowAvailable = 1;
        gageObject.IVFlow = { ...validArray[0] };
      }
      break;
    case '00065':
      if(doubleArray.length < 1) gageObject.streamHeightAvailable = 0;
      else if (doubleArray[doubleArray.length - 1] < 0.0) {
        gageObject.streamHeightAvailable = 2;
      } else {
        const validArray = [];
        for(const value of valuesArray) {
          let validValue = '0.0';
          if(parseFloat(value?.value) >= 0.0) {
            validValue = value?.value;
          }
          validArray.push({ ...value, value: validValue });
        }
        gageObject.streamHeightAvailable = 1;
        gageObject.IVHeight = { ...validArray[0] };
      }
      break;
    case '00010':
      if(doubleArray.length < 1) gageObject.streamTempAvailable = 0;
      else {
        const validArray = [];
        let hasTemp = 0;
        for(const value of valuesArray) {
          const tempC = parseFloat(value?.value) || 0;
          const tempF = ((tempC * (9 / 5)) + 32).toFixed(1);
          if(tempF > 0) {
            hasTemp = 1;
            validArray.push({ ...value, value: String(tempF) });
          }
        }
        if(hasTemp === 1) {
          gageObject.streamTempAvailable = 1;
          gageObject.IVTemp = { ...validArray[0] };
        }
      }
      break;
    default:
      return;
    }
  }

  return gageObject;
};

export const retrieveGages = async (stream, currentHUC) => {
  const { stream_gid } = stream;
  const { nearbyGagesRiverIDs, nearbyGagesWatershedIDs, trueHuc } = await getGageIDList(stream_gid, currentHUC);
  const response = await Promise.allSettled(nearbyGagesRiverIDs?.map((id) => getLatestGageData(id)));
  const response2 = await Promise.allSettled(nearbyGagesWatershedIDs?.map((id) => getLatestGageData(id)));

  return {
    inCurrentRiver: response.filter((result) => result.status === 'fulfilled').map((result) => result.value),
    inCurrentWatershed: response2.filter((result) => result.status === 'fulfilled').map((result) => result.value),
    trueHuc,
  };
};
