/* eslint-disable prefer-destructuring */
import axios from 'axios';
import * as firebaseService from './firebase';

const mapboxUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

/**
 * Initiates a search of the geofeatures database.
 * @param {string} text Query to search by.
 */
export const searchFeatures = async (text) => {
  const snapshot = await firebaseService.getGeoFeatures(text);
  const features = [];
  snapshot.forEach((data) => {
    const feature = {};
    // feature.key = "asjr";
    feature.PrimaryLabel = data.child('properties').child('PrimaryLabel').val();
    feature.SecondaryLabel = data.child('properties').child('SecondaryLabel').val();
    feature.TypeCode = data.child('properties').child('TypeCode').val();
    feature.StreamClass = data.child('properties').child('StreamClass').val();
    feature.popular = data.child('properties').child('is_popular').val();
    feature.properties = data.child('properties').val();
    if (feature.TypeCode === 1) {
      feature.stream_gid2 = data.child('properties').child('stream_gid2').val();
      feature.GeoBoundMaxX2 = data.child('properties').child('GeoBoundMaxX').val();
      feature.GeoBoundMinX2 = data.child('properties').child('GeoBoundMinX').val();
      feature.GeoBoundMaxY2 = data.child('properties').child('GeoBoundMaxY').val();
      feature.GeoBoundMinY2 = data.child('properties').child('GeoBoundMinY').val();
      feature.StreamClass = data.child('properties').child('StreamClass').val();
      feature.HasEasement = data.child('properties').child('has_easement').val();
    } else {
      const coordinates = data.child('geometry').child('coordinates').child(0).val();
      feature.centerLong = coordinates[0];
      feature.centerLat = coordinates[1];
      feature.geometry = [];
      feature.geometry.coordinates = coordinates;
      feature.layer = [];
    }
    if (feature.GeoBoundMaxX2 || feature.centerLat) {
      features.push(feature);
    }
  });
  return features;
};

/**
 * Returns a list of geocded features from the Mapbox API.
 */
export const getMapboxFeatures = async (text) => {
  const { data } = await axios.get(`${mapboxUrl}${encodeURIComponent(text)}.json?access_token=${process.env.REACT_APP_MAPBOX}&limit=10`);
  const { features } = data;
  const featureObjects = [];
  features.forEach((feature) => {
    const featureObj = {};
    featureObj.bbox = feature.bbox;
    featureObj.PrimaryLabel = feature.place_name;
    featureObj.centerLong = feature.center[0];
    featureObj.centerLat = feature.center[1];
    featureObj.relevance = feature?.relevance;
    if (feature?.context.length > 0 && feature.context[feature.context.length - 1].text == 'United States') {
      featureObjects.push(featureObj);
    }
  });
  return featureObjects;
};
