import React, { useState, useEffect, useRef } from 'react';
import Image from 'components/Image';
import PropTypes from 'prop-types';
import ThreeButtons from 'components/ThreeButtons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Line } from 'react-chartjs-2';
import { updateStreamGeography } from 'actions';
import {
  distanceIcon, elevationIcon, linkIcon, flowIcon, heightIcon, tempIcon, aboveIcon, belowIcon, risingIcon, fallingIcon, normalIcon, steadyIcon, viewChartIcon, backIcon, posIcon,
} from 'assets';
import * as firebaseService from 'services/firebase';
import * as gageService from 'services/gage';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  InteractionMode,
  TimeScale,
} from 'chart.js';
import { store } from '../../index';
import styles from './styles.module.scss';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
//   Filler,
//   TimeScale,
// );

const PopupGage = ({
  gage, title, subtitle, type, distance, elevation, leftButton, middleButton, rightButton, iconURL, fourthButton, dispatch, color,
}) => {
  const dateSelect = [{ label: 'Day', value: 'day' }, { label: 'Week', value: 'week' }, { label: 'Month', value: 'month' }, { label: 'Year', value: 'year' }];
  const { tapMarker } = store.getState().map.marker;
  const [url, setUrl] = useState('');
  const [eLink, setELink] = useState('');
  const [gdata, setGData] = useState({
    streamFlowAvailable: 0,
    streamHeightAvailable: 0,
    streamTempAvailable: 0,
  });
  const [flowState, setFlowState] = useState(0);
  const [heightState, setHeightState] = useState(0);
  const [tempState, setTempState] = useState(0);
  const [flowState1, setFlowState1] = useState(0);
  const [heightState1, setHeightState1] = useState(0);
  const [tempState1, setTempState1] = useState(0);
  const [showChart, setShowChart] = useState(false);
  const [tabSel, setTabSel] = useState('flow');
  const [chartTime, setChartTime] = useState('day');
  const [chartData, setChartData] = useState(null);
  const [allChartData, setAllChartData] = useState(null);
  const [medianData, setMedianData] = useState(null);
  const [cursorData, setCursorData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLine, setIsLine] = useState(true);
  const chartRef = useRef(null);

  let networkType = 'USGS';
  if (gage.properties.gage_provider == 'NOAA') {
    networkType = 'NOAA';
  } else if (gage.properties.gage_provider == 'Colorado') {
    networkType = 'Colorado';
  }

  const Selector = ({ items, onSelect, value }) => {
    return (
      <ToggleButtonGroup className={styles.tbuttongroup} value={value} exclusive onChange={(e, val) => { onSelect(val); }} size="small">
        {items.filter((m) => {
          if (networkType === 'NOAA' && m.value === 'year') {
            return false;
          } else {
            return true;
          }
        }).map((item) => {
          return (
            <ToggleButton className={chartTime == item.value ? styles.tbutton_sel : styles.tbutton} value={item.value} key={item.value}>{item.label}</ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    );
  };

  const onClose = async () => {
    const { map } = store.getState().map;
    const { stream } = store.getState().map;
    const { redMarker } = store.getState().map.marker;
    map.fire('closeAllPopups');
    if (tapMarker) {
      tapMarker.remove();
    }
    if (redMarker) {
      redMarker.remove();
    }
    if (stream) {
      stream.isDetail = false;
      if (dispatch) dispatch(updateStreamGeography({ isDetail: false }));
    }
    if (document.getElementsByClassName('mapboxgl-popup').length > 0) document.getElementsByClassName('mapboxgl-popup')[0].remove();
  };
  const onCloseChart = async () => {
    setShowChart(false);
  };
  const viewChart = async () => {
    if (allChartData != null && !isLoading) {
      if (gdata.streamFlowAvailable == 1) {
        setTabSel('flow');
      } else if (gdata.streamHeightAvailable == 1) {
        setTabSel('height');
      } else if (gdata.streamTempAvailable == 1) {
        setTabSel('temp');
      } else {
        setTabSel('flow');
      }
      setShowChart(true);
      setChartTime('week');
    }
  };
  const goChart = async (type) => {
    if (!isLoading) {
      if ((gdata.streamFlowAvailable == 1 && type == 'flow') || (gdata.streamHeightAvailable == 1 && type == 'height') || (gdata.streamTempAvailable == 1 && type == 'temp')) {
        setTabSel(type);
        setShowChart(true);
        setChartTime('week');
      }
    }
  };
  const changeChartTime = async (type) => {
    if (type == 'year' && allChartData.flow.year === undefined) {
      gageService.getGageData(gage.properties.STAID, '', type, null, (res) => {
        const data = { ...allChartData };
        data.flow.year = lineChartdata(res.IVFlowArray);
        data.height.year = lineChartdata(res.IVHeightArray);
        data.temp.year = lineChartdata(res.IVTempArray);
        setAllChartData(data);
        setChartTime(type);
      });
    } else {
      setChartTime(type);
    }
  };

  const getGData = async () => {
    // ======== calculate gage data ========
    setIsLoading(true);
    if (networkType === 'USGS') {
      gageService.getGageData(gage.properties.STAID, '', 'day', null, (res) => {
        const my_gdata = res;
        if (res.streamFlowAvailable == 1) {
          my_gdata.IVFlow = res.IVFlowArray[res.IVFlowArray.length - 1];
        }
        if (res.streamHeightAvailable == 1) {
          my_gdata.IVHeight = res.IVHeightArray[res.IVHeightArray.length - 1];
        }
        if (res.streamTempAvailable == 1) {
          my_gdata.IVTemp = res.IVTempArray[res.IVTempArray.length - 1];
        }
        setGData(my_gdata);
      });
    } else if (networkType === 'NOAA' || networkType === 'Colorado') {
      let res = {
        IVFlowArray: [],
        IVHeightArray: [],
        IVTempArray: [],
      };
      if (networkType === 'NOAA') {
        res.id = gage.properties.lid;
        res = await gageService.fetchObservedDataForNOAAGage(res);
      } else {
        res.id = gage.properties.abbrev;
        res = await gageService.fetchObservedDataForColoradoGage(res);
      }
      const my_gdata = res;
      if (res.streamFlowAvailable == 1) {
        my_gdata.IVFlow = res.IVFlowArray[res.IVFlowArray.length - 1];
      }
      if (res.streamHeightAvailable == 1) {
        my_gdata.IVHeight = res.IVHeightArray[res.IVHeightArray.length - 1];
      }
      if (res.streamTempAvailable == 1) {
        my_gdata.IVTemp = res.IVTempArray[res.IVTempArray.length - 1];
      }
      setGData(my_gdata);
    }
    // ================================
  };

  useEffect(() => {
    let eURL = networkType;
    eURL += ' ';
    if (networkType == 'Colorado') {
      eURL += gage.properties.abbrev;
      setELink(`https://dwr.state.co.us/Tools/StationsLite/${gage.properties.abbrev}`);
    } else if (networkType == 'NOAA') {
      eURL += gage.properties.lid;
      setELink(`https://water.noaa.gov/gauges/${gage.properties.lid}`);
    } else {
      eURL += gage.properties.STAID;
      setELink(`https://waterdata.usgs.gov/monitoring-location/${gage.properties.STAID}`);
    }
    setUrl(eURL);
    getGData();
  }, []);

  useEffect(() => {
    if (allChartData != null) {
      const data = allChartData[tabSel] ? allChartData[tabSel][chartTime] : null;
      setChartData(data);
      setCursorData('');
      if (data != null) {
        const date = data.datasets[0].data[data.datasets[0].data.length - 1];
        if (date) {
          let pos = date.y;
          if (tabSel == 'flow') {
            pos += ' CFS';
          } else if (tabSel == 'height') {
            pos += ' FT';
          } else {
            pos += ' ºF';
          }
          pos += ' | ';
          if (date.x._tzm) {
            pos += date.x.utcOffset(date.x._tzm).format('M/D/YYYY | h:mm A');
          } else {
            pos += date.x.format('M/D/YYYY | h:mm A');
          }
          pos += ' MDT';
          setCursorData(pos);
        }
      }
    }
  }, [tabSel, chartTime]);

  const timeFilter = (result, ctime, isFuture = false) => {
    let dtime = result.dateTime;
    if (dtime.length < 10) {
      dtime = convertDate(dtime);
    }
    switch (ctime) {
    case 'day': {
      const diff = new Date().getTime() - new Date(dtime).getTime();
      const minutes = diff / (1000 * 60);
      return minutes < 1440 && (isFuture ? diff > 0 : 1);
    }
    case 'week': {
      const diff = new Date().getTime() - new Date(dtime).getTime();
      const minutes = diff / (1000 * 60);
      return minutes < 1440 * 7 && (isFuture ? diff > 0 : 1);
    }
    case 'month': {
      const diff = new Date().getTime() - new Date(dtime).getTime();
      const minutes = diff / (1000 * 60);
      return minutes < 1440 * 30 && (isFuture ? diff > 0 : 1);
    }
    case 'year': {
      const diff = new Date().getTime() - new Date(dtime).getTime();
      return (isFuture ? diff > 0 : 1);
    }
    default:
      return true;
    }
  };

  const getGageData = (gageData, mData) => {
    // const { gageData } = store.getState().map;
    const today = moment().format('YYYY-MM-DD');
    const today_m = moment().format('YYYY-M-D');
    const dayDataFlow = [];
    const dayDataHeight = [];
    const dayDataTemp = [];
    const weekDataFlow = [];
    const weekDataHeight = [];
    const weekDataTemp = [];
    const monthDataFlow = [];
    const monthDataHeight = [];
    const monthDataTemp = [];
    const yearDataFlow = [];
    const yearDataHeight = [];
    const yearDataTemp = [];

    gageData.IVFlowArray.filter((result) => result.value !== '-999999').forEach((a) => {
      if (a.dateTime.split('T')[0] == today || timeFilter(a, 'day')) {
        dayDataFlow.push(a);
      }
      if (timeFilter(a, 'week')) {
        weekDataFlow.push(a);
      }
      monthDataFlow.push(a);
      yearDataFlow.push(a);
    });
    gageData.IVHeightArray.filter((result) => result.value !== '-999999').forEach((a) => {
      if (a.dateTime.split('T')[0] == today || timeFilter(a, 'day')) {
        dayDataHeight.push(a);
      }
      if (timeFilter(a, 'week')) {
        weekDataHeight.push(a);
      }
      monthDataHeight.push(a);
      yearDataHeight.push(a);
    });
    gageData.IVTempArray.filter((result) => result.value !== '-999999').forEach((a) => {
      if (a.dateTime.split('T')[0] == today || timeFilter(a, 'day')) {
        dayDataTemp.push(a);
      }
      if (timeFilter(a, 'week')) {
        weekDataTemp.push(a);
      }
      monthDataTemp.push(a);
      yearDataTemp.push(a);
    });
    if (gdata.streamFlowAvailable == 1 && dayDataFlow.length > 0) {
      let total = 0;
      dayDataFlow.forEach((a) => {
        total += a.value * 1;
      });
      total /= dayDataFlow.length;
      if (mData.medianFlowArray) {
        total = mData.medianFlowArray.find((m) => m.dateTime == today_m);
      }
      if (total && gdata.IVFlow.value >= total.value * 1.1) {
        setFlowState(1);
      } else if (total && gdata.IVFlow.value <= total.value * 0.9) {
        setFlowState(-1);
      } else {
        setFlowState(0);
      }
    }
    if (gdata.streamHeightAvailable == 1 && dayDataHeight.length > 0) {
      let total = 0;
      dayDataHeight.forEach((a) => {
        total += a.value * 1;
      });
      total /= dayDataHeight.length;
      if (mData.medianHeightArray) {
        total = mData.medianHeightArray.find((m) => m.dateTime == today_m);
      }
      if (total && gdata.IVHeight.value >= total.value * 1.1) {
        setHeightState(1);
      } else if (total && gdata.IVHeight.value <= total.value * 0.9) {
        setHeightState(-1);
      } else {
        setHeightState(0);
      }
    }
    if (gdata.streamTempAvailable == 1 && dayDataTemp.length > 0) {
      let total = 0;
      dayDataTemp.forEach((a) => {
        total += a.value * 1;
      });
      total /= dayDataTemp.length;
      if (mData.medianTempArray) {
        total = mData.medianTempArray.find((m) => m.dateTime == today_m);
      }
      if (total && gdata.IVTemp.value >= total.value * 1.1) {
        setTempState(1);
      } else if (total && gdata.IVTemp.value <= total.value * 0.9) {
        setTempState(-1);
      } else {
        setTempState(-1);
      }
    }
    if (gdata.streamFlowAvailable == 1 && monthDataFlow.length > 0) {
      let total = 0;
      monthDataFlow.forEach((a) => {
        total += a.value * 1;
      });
      total /= monthDataFlow.length;
      if (gdata.IVFlow.value >= total * 1.1) {
        setFlowState1(1);
      } else if (gdata.IVFlow.value <= total * 0.9) {
        setFlowState1(-1);
      }
    }
    if (gdata.streamHeightAvailable == 1 && monthDataHeight.length > 0) {
      let total = 0;
      monthDataHeight.forEach((a) => {
        total += a.value * 1;
      });
      total /= monthDataHeight.length;
      if (gdata.IVHeight.value >= total * 1.1) {
        setHeightState1(1);
      } else if (gdata.IVHeight.value <= total * 0.9) {
        setHeightState1(-1);
      }
    }
    if (gdata.streamTempAvailable == 1 && monthDataTemp.length > 0) {
      let total = 0;
      monthDataTemp.forEach((a) => {
        total += a.value * 1;
      });
      total /= monthDataTemp.length;
      if (gdata.IVTemp.value >= total * 1.1) {
        setTempState1(1);
      } else if (gdata.IVTemp.value <= total * 0.9) {
        setTempState1(-1);
      }
    }

    const data = {
      flow: {
        day: lineChartdata(dayDataFlow, mData, 'flow', 'day'),
        week: lineChartdata(weekDataFlow, mData, 'flow', 'week'),
        month: lineChartdata(monthDataFlow, mData, 'flow', 'month'),
        // year: lineChartdata(yearDataFlow),
      },
      height: {
        day: lineChartdata(dayDataHeight, mData, 'height', 'day'),
        week: lineChartdata(weekDataHeight, mData, 'height', 'week'),
        month: lineChartdata(monthDataHeight, mData, 'height', 'month'),
        // year: lineChartdata(yearDataHeight),
      },
      temp: {
        day: lineChartdata(dayDataTemp, mData, 'temp', 'day'),
        week: lineChartdata(weekDataTemp, mData, 'temp', 'week'),
        month: lineChartdata(monthDataTemp, mData, 'temp', 'month'),
        // year: lineChartdata(yearDataTemp),
      },
    };

    if (monthDataFlow.length == 0 && monthDataHeight.length == 0 && monthDataTemp.length == 0) {
      setAllChartData(null);
    } else if (networkType === 'USGS') {
      gageService.getGageData(gage.properties.STAID, '', 'year', null, (res) => {
        data.flow.year = lineChartdata(res.IVFlowArray, mData, 'flow', 'year');
        data.height.year = lineChartdata(res.IVHeightArray, mData, 'height', 'year');
        data.temp.year = lineChartdata(res.IVTempArray, mData, 'temp', 'year');
        setAllChartData(data);
      });
    } else {
      setAllChartData(data);
    }
  };
  const convertDate = (userDate) => {
    // format from YYYY-M-D to YYYY-MM-DD
    const array = userDate.split('-');
    if (array[1].length < 2) {
      array[1] = `0${array[1]}`;
    }
    if (array[2].length < 2) {
      array[2] = `0${array[2]}`;
    }
    const arrayAnswer = `${array[0]}-${array[1]}-${array[2]}`;
    return arrayAnswer;
  };
  const capitalize = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const lineChartdata = (result, mData, type = 'flow', time = 'week') => {
    const temp = result;
    // if (result.length > 1000 && 0) {
    //   const temp1 = {};
    //   result.forEach((a) => {
    //     const date = result.length > 4000 ? a.dateTime.split('T')[0] : a.dateTime.split(':')[0];
    //     temp1[date] = a;
    //   });
    //   Object.keys(temp1).forEach((k) => {
    //     temp.push(temp1[k]);
    //   });
    // } else {
    //   temp = result;
    // }
    const data_c = [];
    const data_m = [];
    temp.forEach((a) => {
      data_c.push({ x: moment(a.dateTime), y: a.value * 1 });
    });
    if (networkType === 'USGS') {
      if (type == 'flow') {
        mData.medianFlowArray.forEach((a) => {
          const dtime = convertDate(a.dateTime);
          if (time == 'day' && timeFilter(a, 'day', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'week' && timeFilter(a, 'week', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'month' && timeFilter(a, 'month', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'year' && timeFilter(a, 'year', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          }
        });
      } else if (type == 'height') {
        mData.medianHeightArray.forEach((a) => {
          const dtime = convertDate(a.dateTime);
          if (time == 'day' && timeFilter(a, 'day', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'week' && timeFilter(a, 'week', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'month' && timeFilter(a, 'month', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'year' && timeFilter(a, 'year', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          }
        });
      } else if (type == 'temp') {
        mData.medianTempArray.forEach((a) => {
          const dtime = convertDate(a.dateTime);
          if (time == 'day' && timeFilter(a, 'day', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'week' && timeFilter(a, 'week', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'month' && timeFilter(a, 'month', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          } else if (time == 'year' && timeFilter(a, 'year', true)) {
            data_m.push({ x: moment(dtime), y: a.value * 1 });
          }
        });
      }
    }
    const data = {
      // labels: [...temp.map((a) => moment(a.dateTime).format('M/D h:mm A'))],
      datasets: [{
        type: 'line',
        label: capitalize(type),
        fill: false,
        borderColor: 'rgb(21, 83, 211)',
        backgroundColor: 'rgb(255, 255, 255)',
        pointRadius: 0,
        pointHoverRadius: 0,
        pointHoverBorderWidth: 0,
        pointHoverBackgroundColor: 'rgb(21, 83, 211)',
        borderWidth: 2,
        data: data_c,
      }, {
        type: 'line',
        label: 'Historical Median',
        fill: false,
        borderColor: 'rgb(70, 70, 70)',
        backgroundColor: 'rgb(255, 255, 255)',
        pointRadius: 0,
        pointHoverRadius: 0,
        pointStyle: false,
        borderWidth: 1,
        data: data_m,
      }],
    };
    return data;
  };

  const DrawChart = ({ data }) => {
    let ticks = 7;
    if (chartTime === 'year') ticks = 5;
    // data.datasets[1]
    const options = {
      responsive: true,
      onHover: (event, chartElement) => {
        if (chartElement.length > 0) {
          const idx = chartElement[0]._index;
          const data = chartData.datasets[0].data[idx];
          const { chart } = chartRef.current.chartInstance;
          const xPos = chart.scales['x-axis-0'].getPixelForValue(data.x);

          document.getElementById('vline').style.display = 'block';
          document.getElementById('vline').style.left = `${xPos}px`;

          let pos = data.y;
          if (tabSel == 'flow') {
            pos += ' CFS';
          } else if (tabSel == 'height') {
            pos += ' FT';
          } else {
            pos += ' ºF';
          }
          pos += ' | ';
          if (data.x._tzm) {
            pos += data.x.utcOffset(data.x._tzm).format('M/D/YYYY | h:mm A');
          } else {
            pos += data.x.format('M/D/YYYY | h:mm A');
          }
          pos += ' MDT';
          document.getElementById('cursorData').innerHTML = pos;
        } else {
          // document.getElementById('vline').style.display = 'none';
        }
      },
      hover: {
        mode: 'x',
        intersect: false,
      },
      animation: {
        duration: 0,
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          boxWidth: 24,
          boxHeight: 10,
          fontSize: 12,
        },
      },
      tooltips: {
        enabled: false,
      },
      // plugins: {
      // },
      scales: {
        xAxes: [{
          type: 'time',
          ticks: {
            maxTicksLimit: ticks,
            maxRotation: 0,
          },
        }],
        yAxes: [{
          ticks: {
            maxTicksLimit: 5,
            beginAtZero: true,
          },
        }],
      },
    };
    return (
      <Line data={data}
        height={250}
        options={options}
        ref={chartRef}
      />
    );
  };

  useEffect(async () => {
    if (gdata.id) {
      if (gdata.streamFlowAvailable == 1 || gdata.streamHeightAvailable == 1 || gdata.streamTempAvailable == 1) {
        if (networkType === 'USGS') {
          gageService.getGageData(gage.properties.STAID, '', 'month', null, (res) => {
            const median = {
              id: gdata.id,
              medianHeightArray: [],
              medianFlowArray: [],
              medianTempArray: [],
            };
            gageService.getGageMedianData(median, 'daily', (res1) => {
              setMedianData(res1);
              getGageData(res, res1);
            });
          });
        } else if (networkType === 'NOAA' || networkType === 'Colorado') {
          getGageData(gdata, []);
        }
      } else {
        setIsLoading(false);
      }
    }
  }, [gdata]);

  useEffect(() => {
    if (gdata.id) {
      if (gdata.streamFlowAvailable == 1 || gdata.streamHeightAvailable == 1 || gdata.streamTempAvailable == 1) {
        setIsLoading(false);
      }
    }
  }, [allChartData]);

  const idState = (st) => {
    if (st == 1) {
      return 'Above Normal';
    } else if (st == -1) {
      return 'Below Normal';
    } else {
      return 'Normal';
    }
  };
  const idState1 = (st) => {
    if (st == 1) {
      return 'Rising';
    } else if (st == -1) {
      return 'Falling';
    } else {
      return 'Steady';
    }
  };
  const idTemp = (st) => {
    if (st < 65) {
      return 'Optimal';
    } else if (st >= 68) {
      return 'Too Hot';
    } else {
      return 'Caution';
    }
  };
  const idTempStyle = (st) => {
    if (st < 65) {
      return { backgroundColor: '#65B604' };
    } else if (st >= 68) {
      return { backgroundColor: '#DB0000' };
    } else {
      return { backgroundColor: '#F5A523' };
    }
  };

  return (
    <>
      {!dispatch && (
        <div className={styles.popup}>
          {!showChart && (
            <div className={styles.popupCont}>
              <div className={styles.header}>
                <div className={styles.close}
                  role="button"
                  aria-label={title}
                  onClick={onClose}
                  tabIndex={0}
                >
                  <CloseIcon />
                </div>
                <div className={styles.text}>
                  <div className={styles.type}>{iconURL && <Image alt={title} src={iconURL} width="20" />}<h6>{type}</h6></div>
                  <h4>{title}</h4>
                  <h5>{subtitle}</h5>
                </div>
              </div>

              <div className={styles.distances}>
                <div className={styles.distanceInner}>
                  <Image alt="Distance" src={distanceIcon} width="20" />
                  <p>{distance}</p>
                </div>
                <div className={styles.distanceInner}>
                  <Image alt="Elevation" src={elevationIcon} width="20" />
                  <p>{Number(elevation).toLocaleString('en-US', { minimumFractionDigits: 0 })} ft elev.</p>
                </div>
              </div>

              <div className={styles.distances} style={{ justifyContent: 'space-between' }}>
                {networkType === 'USGS' && gage.properties.URL && (<a className={styles.url} href={gage.properties.URL} rel="noreferrer" target="_blank">{url}<Image alt="USGS" src={linkIcon} width="16" /></a>)}
                {networkType === 'USGS' && !gage.properties.URL && (<div className={styles.surl}>{url}</div>)}
                {networkType === 'NOAA' && (<a className={styles.url} href={eLink} rel="noreferrer" target="_blank">{url}</a>)}
                {networkType === 'Colorado' && (<a className={styles.url} href={eLink} rel="noreferrer" target="_blank">{url}</a>)}
              </div>

              {gdata !== null && (
                <div className={styles.gdata}>
                  <div className={styles.viewcard} role="button" tabIndex={0} onClick={() => goChart('flow')}>
                    <Image alt="Flow" src={flowIcon} width="32" />
                    <div>
                      <div className={styles.gtitle}>Flow<span>{gdata.streamFlowAvailable == 1 ? `: ${gdata.IVFlow.value} CFS` : ''}</span></div>
                      {gdata.streamFlowAvailable == 1 && !isLoading && (
                        <div className={styles.normal}>
                          {flowState == 1 && (<Image src={aboveIcon} width="16" />)}
                          {flowState == -1 && (<Image src={belowIcon} width="16" />)}
                          {flowState == 0 && (<Image src={normalIcon} width="14" />)}
                          {idState(flowState)}
                          {flowState1 == 1 && (<Image src={risingIcon} width="16" />)}
                          {flowState1 == -1 && (<Image src={fallingIcon} width="16" />)}
                          {flowState1 == 0 && (<Image src={steadyIcon} width="16" />)}
                          {idState1(flowState1)}
                        </div>
                      )}
                      {gdata.streamFlowAvailable != 1 && !isLoading && (<div className={styles.normal}>No Data</div>)}
                      {isLoading && (<div className={styles.normal}>-</div>)}
                    </div>
                    {gdata.streamFlowAvailable == 1 && !isLoading && (<div className={styles.viewdata}>VIEW DATA</div>)}
                  </div>

                  <div className={styles.viewcard} role="button" tabIndex={0} onClick={() => goChart('height')}>
                    <Image alt="Flow" src={heightIcon} width="32" />
                    <div>
                      <div className={styles.gtitle}>Height<span>{gdata.streamHeightAvailable == 1 ? `: ${gdata.IVHeight.value} FT` : ''}</span></div>
                      {gdata.streamHeightAvailable == 1 && !isLoading && (
                        <div className={styles.normal}>
                          {heightState == 1 && (<Image src={aboveIcon} width="16" />)}
                          {heightState == -1 && (<Image src={belowIcon} width="16" />)}
                          {heightState == 0 && (<Image src={normalIcon} width="14" />)}
                          {idState(heightState)}
                          {heightState1 == 1 && (<Image src={risingIcon} width="16" />)}
                          {heightState1 == -1 && (<Image src={fallingIcon} width="16" />)}
                          {heightState1 == 0 && (<Image src={steadyIcon} width="16" />)}
                          {idState1(heightState1)}
                        </div>
                      )}
                      {gdata.streamHeightAvailable != 1 && !isLoading && (<div className={styles.normal}>No Data</div>)}
                      {isLoading && (<div className={styles.normal}>-</div>)}
                    </div>
                    {gdata.streamHeightAvailable == 1 && !isLoading && (<div className={styles.viewdata}>VIEW DATA</div>)}
                  </div>

                  <div className={styles.viewcard} role="button" tabIndex={0} onClick={() => goChart('temp')}>
                    <Image alt="Flow" src={tempIcon} width="32" />
                    <div>
                      <div className={styles.gtitle}>Temperature<span>{gdata.streamTempAvailable == 1 ? `: ${gdata.IVTemp.value} ºF` : ''}</span></div>
                      {gdata.streamTempAvailable == 1 && !isLoading && (
                        <div className={styles.normal}>
                          <div className={styles.circle} style={idTempStyle(gdata.IVTemp.value)} />
                          {idTemp(gdata.IVTemp.value)}
                        </div>
                      )}
                      {gdata.streamTempAvailable != 1 && !isLoading && (<div className={styles.normal}>No Data</div>)}
                      {isLoading && (<div className={styles.normal}>-</div>)}
                    </div>
                    {gdata.streamTempAvailable == 1 && !isLoading && (<div className={styles.viewdata}>VIEW DATA</div>)}
                  </div>
                </div>
              )}

              <ThreeButtons
                leftButton={{
                  label: 'View Charts',
                  icon: viewChartIcon,
                  onClick: () => { viewChart(); },
                }}
                middleButton={middleButton}
                rightButton={rightButton}
                fourthButton={fourthButton}
                isLoading={isLoading}
              />
            </div>
          )}

          {showChart && (
            <div className={styles.popupCont}>
              <div className={styles.header}>
                <div className={styles.close}
                  role="button"
                  aria-label={title}
                  onClick={onCloseChart}
                  tabIndex={0}
                >
                  <CloseIcon />
                </div>
                <div className={styles.back} role="button" aria-label={title} onClick={onCloseChart} tabIndex={0}>
                  <Image alt={title} src={backIcon} width="16" />
                  <div>Back</div>
                </div>
                <div className={styles.text} style={{ paddingTop: 32 }}>
                  <div className={styles.tabNetwork}>{networkType} Gage</div>
                  <h4>{title}</h4>
                </div>
              </div>

              {gdata !== null && (
                <>
                  <div className={styles.tabsWrap}>
                    <div className={styles.tabs}>
                      {gdata.streamFlowAvailable == 1 && (
                        <div className={`${styles.tab} ${tabSel == 'flow' ? styles.sel : ''}`} role="button" tabIndex={0} onClick={() => setTabSel('flow')}>
                          <div>Flow</div>
                          <div className={styles.tabval}>
                            <span>{gdata.IVFlow.value} CFS</span>
                            {flowState == 1 && (<Image src={aboveIcon} width="16" />)}
                            {flowState == -1 && (<Image src={belowIcon} width="16" />)}
                            {flowState == 0 && (<Image src={normalIcon} width="14" />)}
                          </div>
                        </div>
                      )}
                      {gdata.streamHeightAvailable == 1 && (
                        <div className={`${styles.tab} ${tabSel == 'height' ? styles.sel : ''}`} role="button" tabIndex={0} onClick={() => setTabSel('height')}>
                          <div>Height</div>
                          <div className={styles.tabval}>
                            <span>{gdata.IVHeight.value} FT</span>
                            {heightState == 1 && (<Image src={aboveIcon} width="16" />)}
                            {heightState == -1 && (<Image src={belowIcon} width="16" />)}
                            {heightState == 0 && (<Image src={normalIcon} width="14" />)}
                          </div>
                        </div>
                      )}
                      {gdata.streamTempAvailable == 1 && (
                        <div className={`${styles.tab} ${tabSel == 'temp' ? styles.sel : ''}`} role="button" tabIndex={0} onClick={() => setTabSel('temp')}>
                          <div>Temp</div>
                          <div className={styles.tabval}>
                            <span>{gdata.IVTemp.value} ºF</span>
                            <div className={styles.circle} style={idTempStyle(gdata.IVTemp.value)} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.chartCont}>
                    <div className={styles.chartpos}>
                      <Image src={posIcon} width="24" />
                      <div id="cursorData">{cursorData}</div>
                    </div>
                    <Selector
                      items={dateSelect}
                      onSelect={(val) => { changeChartTime(val); }}
                      value={chartTime}
                    />
                    <div className={styles.drawCont}>
                      {chartData != null && (
                        <DrawChart
                          data={chartData}
                        />
                      )}
                      {isLine && (<div id="vline" className={styles.line} />)}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

PopupGage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string,
  distance: PropTypes.string,
  elevation: PropTypes.string,
  leftButton: PropTypes.shape({
    label: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  }),
  middleButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  rightButton: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  iconURL: PropTypes.string,
};

export default PopupGage;
